import React, { useState } from 'react'
import './index.scss'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import { Parallax } from 'react-scroll-parallax'

import MainFrameCover from '../components/MainFrameCover'
import Intro from '../animations/Intro'
import { useInView } from 'react-intersection-observer'

import Slider from 'react-slick'
import ACFWYSIWYG from '../components/Content/ACF/WYSIWYG'

import Footer from '../components/Footer'

// import Modal from '../components/Modal'


function Page(props) {
  const { data, pageContext } = props

  const [refHero, refHeroInView] = useInView({ triggerOnce: true })

  const [refArticles, refArticlesInView] = useInView({ triggerOnce: true })

  const articles = data?.directus?.articles?.map((node,i) => {
    return (
      <div key={i} className='single-article span-6 span-12-mobile'>
        <Intro visible={refArticlesInView} in={{ fadeRight: 750 }} delayIn={150 * i} mounted={true} stay={true} className="padd-half">
          <Link to={`/articles/${node.slug}`}>
            <div className='aspect-ratio'>
              <GatsbyImage
                image={node.image?.imageFile?.childImageSharp?.gatsbyImageData}
                alt=""
                layout="fixed"
                objectFit="cover"
                objectPosition={"50% 50%"}
                className="bg"
              />
            </div>
            <div className='content'>
              <div className='heading'>
                <h4>{node.translations[0]?.title}</h4> 
              </div>
              <div className='summary fs-85 '>
                <ACFWYSIWYG wysiwyg={node.translations[0]?.summary} />
              </div>
            </div>
          </Link>
        </Intro>
      </div>
    )
  })


	const [refAbout, refAboutInView] = useInView({ triggerOnce: true })
  const aboutCopy = <>
    <h2>Co robimy</h2>
    <p><strong>Nasza fundacja, istniejąca od 2015 roku realizuje projekty w Polsce i w Ukrainie:</strong></p>
    <p>
      <ul>
        <li>Zaopatrzenie medyczne i żywnościowe mieszkańców Ukrainy;</li>
        <li>Konwoje humanitarne na terenie Polski i Ukrainy;</li>
        <li>
        Dostarczanie sprzętu ochrony osobistej, taki jak polary, rękawiczki, koce, lekarstwa, woda;
        </li>
        <li>
        Pomoc w relokacji uchodźców;

        </li>
        <li>
        Działania edukacyjne kierowane do dzieci poniżej 18 roku życia;


        </li>
        <li>
        Bezpośrednia koordynacja działań transgranicznych z władzami polskimi, rumuńskimi i ukraińskimi;

        </li>
        <li>
        Zapewnienie miejsc noclegowych z wyżywieniem;

        </li>
        <li>
        Aktywizacja zawodowa obywateli RP z zakresu bezpieczeństwa i ochrony;


        </li>
        <li>
        Międzynarodowa kooperacja na rzecz rozwoju demokracji.


        </li>
      </ul>


</p>
<p>
Nasze konwoje działają codziennie, niezależnie od niebezpieczeństwa w danym regionie – ludzie z kraju ogarniętym wojną nie mogą czekać na pomoc.

</p><p>
<strong>Potrzebujemy Państwa wsparcia finansowego, by móc codziennie pomagać uchodźcom z Ukrainy!</strong>

</p>
  </>

  const [refAccounts, refAccountsInView] = useInView({ triggerOnce: true })
  
	const [currentTab, setCurrentTab] = useState(0)
  
	// On Parent Tab Change
	function onTabChange(i,blank) {
		if(blank === (-1)) {
			setCurrentTab(i)
		} else {
			return false
		}
	}

 
  const [refSlider, refSliderInView] = useInView({ triggerOnce: true })  

  const SliderArrowNext = ({to, onClick}) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-next-positiner hover-trigger`} aria-label={to}>
        {/* <div className="text">NEXT</div> */}
        <div className="icon"></div>
      </div>
    )
  }

  const SliderArrowPrev = ({to, onClick}) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-prev-positiner hover-trigger`} aria-label={to}>
        <div className="icon"></div>
        {/* <div className="text">PREVIOUS</div> */}
      </div>
    )
  }

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
    nextArrow: <SliderArrowNext to="next"/>,
    prevArrow: <SliderArrowPrev to="prev"/>,
		slidesToShow: 2,
		slidesToScroll: 1,
    autoplay: true,

    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
	}

  const slides = 
    <Slider {...settings}>
      <div className={`aspect-ratio`}>
        <StaticImage
          src={'../../static/images/sections/slider/slide-1.jpg'}
          alt="Peter's Pizza Slide, New York Flava - Florida Made"
          layout="fixed"
          objectFit="cover"
          objectPosition={'50% 50%'}
          placeholder='NONE'
        />
      </div>
      <div className={`aspect-ratio`}>
        <StaticImage
          src={'../../static/images/sections/slider/slide-2.jpg'}
          alt="Peter's Pizza Slide, New York Flava - Florida Made"
          layout="fixed"
          objectFit="cover"
          objectPosition={'50% 50%'}
          placeholder='NONE'
        />
      </div>
      <div className={`aspect-ratio`}>
        <StaticImage
          src={'../../static/images/sections/slider/slide-3.jpg'}
          alt="Peter's Pizza Slide, New York Flava - Florida Made"
          layout="fixed"
          objectFit="cover"
          objectPosition={'50% 50%'}
          placeholder='NONE'
        />
      </div>
      <div className={`aspect-ratio`}>
        <StaticImage
          src={'../../static/images/sections/slider/slide-4.jpg'}
          alt="Peter's Pizza Slide, New York Flava - Florida Made"
          layout="fixed"
          objectFit="cover"
          objectPosition={'50% 50%'}
          placeholder='NONE'
        />
      </div>
      <div className={`aspect-ratio`}>
        <StaticImage
          src={'../../static/images/sections/slider/slide-5.jpg'}
          alt="Peter's Pizza Slide, New York Flava - Florida Made"
          layout="fixed"
          objectFit="cover"
          objectPosition={'50% 50%'}
          placeholder='NONE'
        />
      </div>
      <div className={`aspect-ratio`}>
        <StaticImage
          src={'../../static/images/sections/slider/slide-6.jpg'}
          alt="Peter's Pizza Slide, New York Flava - Florida Made"
          layout="fixed"
          objectFit="cover"
          objectPosition={'50% 50%'}
          placeholder='NONE'
        />
      </div>

    </Slider>

    const [refAboutBottom, refAboutBottomInView] = useInView({ triggerOnce: true })

    const aboutBottomCopy = <>
    <h3>O nas</h3>
    <p>Fundacja Instytut Współpracy Międzynarodowej Bezpieczny Wschód (IWM Bezpieczny Wschód) jest polską organizacją pozarządową działającą od 2015 roku. Wojna w Ukrainie stworzyła nowe potrzeby, na które czujemy się zobowiązani reagować. Z Państwa pomocą będzie możliwe zapewnienie lepszego komfortu życia osobom mniej uprzywilejowanym przez los.</p>
    </>

  return (
    <>

      <MainFrameCover />

      <main id="mainframe" className={`active main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug}`}>

        <SEO title={"Instytut Współpracy Międzynarodowej"} description={"Instytut Współpracy Międzynarodowej Bezpieczny Wschód"} />
        
        <section ref={refHero} className="section-hero">
          <Intro visible={refHeroInView} in={{ appear: 0, bg: 1000 }} delayIn={0} mounted={true} stay={true} className="c0 t padd-top">
            <Intro visible={refHeroInView} in={{ fadeBottom: 800 }} delayIn={100} mounted={true} stay={true} className="">      
              <div className='heading padd-2 padd-top  padd-bottom-off'>
              <div className="aspect-ratio">
                  <StaticImage
                    src={'../images/logo.png'}
                    layout="fixed"
                    objectFit="contain"
                    objectPosition={'50% 50%'}
                    placeholder='NONE'
                    alt="Peter's Pizza, New York Flava - Florida Made"
                  />
                </div>
                {/* <h1> Instytut Współpracy <br/> Międzynarodowej  <br/>Bezpieczny <span className='colored'>Wschód</span></h1> */}
              </div>
            </Intro>
            <Parallax translateY={[10, -20]} tagOuter="figure">
              <Intro visible={refHeroInView} in={{ fadeBottom: 800 }} delayIn={400} mounted={true} stay={true} className="hero-logo padd-2 padd-top padd-bottom-off">
                <div className="aspect-ratio">
                  <StaticImage
                    src={'../../static/images/sections/hero/hero.jpg'}
                    layout="fixed"
                    objectFit="cover"
                    objectPosition={'50% 50%'}
                    placeholder='NONE'
                    alt="Peter's Pizza, New York Flava - Florida Made"
                  />
                </div>
              </Intro>
            </Parallax>

          </Intro>
        </section>

        <section ref={refAbout} className="section-about c0 padd-top padd-bottom padd-right-2 padd-left-2">
     
              <div className="flex-12 about-container text-justify ai-center jc-space-around">
                <div className='span-6 span-12-mobile flex-12'>
                  <div className='span-6 span-12-mobile padd padd-half'>
                    <div className="aspect-ratio ">
                      <StaticImage
                        src={'../../static/images/sections/about/img-top-1.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt=""
                      />
                    </div>
                  </div>
                  <div className='span-6 padd-half'>
                    <div className="aspect-ratio ">
                      <StaticImage
                        src={'../../static/images/sections/about/img-top-2.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt=""
                      />
                    </div>
                  </div>
                  <div className='span-6 padd-half'>
                    <div className="aspect-ratio ">
                      <StaticImage
                        src={'../../static/images/sections/about/img-top-3.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt=""
                      />
                    </div>
                  </div>
                  <div className='span-6 span-12-mobile padd-half'>
                    <div className="aspect-ratio ">
                      <StaticImage
                        src={'../../static/images/sections/about/img-top-4.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="overflow-visible copy position-relative span-6 span-12-mobile">
                  <Intro visible={refAboutInView} in={{ fadeRight: 750 }} delayIn={400} mounted={true} stay={true} className="">
                    {aboutCopy}
                  </Intro>
                </div>
              </div>
              <div className="flex-12 about-container text-justify ai-center jc-space-around">
                <div className='span-12 flex-12'>
                  <div className='span-3 span-6-mobile padd padd-half'>
                    <div className="aspect-ratio ">
                      <StaticImage
                        src={'../../static/images/sections/about/img-top-5.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt=""
                      />
                    </div>
                  </div>
                  <div className='span-3 span-6-mobile padd-half'>
                    <div className="aspect-ratio ">
                      <StaticImage
                        src={'../../static/images/sections/about/img-top-6.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt=""
                      />
                    </div>
                  </div>
                  <div className='span-3 span-6-mobile padd padd-half'>
                    <div className="aspect-ratio ">
                      <StaticImage
                        src={'../../static/images/sections/about/img-top-7.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt=""
                      />
                    </div>
                  </div>
                  <div className='span-3 span-6-mobile padd-half'>
                    <div className="aspect-ratio ">
                      <StaticImage
                        src={'../../static/images/sections/about/img-top-8.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div style={{maxWidth: '40em', margin: '0 auto', padding: '2em 0'}} className='text-center fs-125 span-12'>
                  <p>Obecnie poza pomocą w Ukrainie pracujemy nad stworzeniem placówek aktywizacyjnych na terenie większych miast w Polsce. Placówki takie mają posiadać wsparcie w postaci tłumacza, pomocy medycznej, psychologów, doradców zawodowych.</p>
                  <p>W projekcie mamy również zorganizowanie miejsc opiekuńczych – świetlic środowiskowych, klubów malucha – dla dzieci, tak aby ukraińskie mamy mogły podejmować prace zarobkowa bez obawy o bezpieczeństwo dzieci.</p>
                </div>
  
              </div>
        </section>

        <section ref={refArticles} className="section-articles c0">
          <div className='block-wrapper flex-12'>
            <div className='span-4 span-12-mobile flex ai-center jc-center'>
              <div className='heading padd-half '>
                <h2>Blog</h2>
              </div>
            </div>
            <div className='span-8 span-12-mobile'>
              <div className='flex-12 articles-group padd-half'>
                {articles}
              </div>
            </div>
          </div>
        </section>
        
        <section ref={refAboutBottom} className="section-about-bottom c0 padd-top-half padd-bottom padd-right-off padd-left-off">
          <div className="overflow-visible copy position-relative fs-125">
            <div className='wrapper-limit'>
              <Intro visible={refAboutInView} in={{ fadeRight: 750 }} delayIn={400} mounted={true} stay={true} className="">
                {aboutBottomCopy}
              </Intro>
            </div>
          </div>
        </section>

        <section ref={refSlider} className="section-slider c0 padd-top-half padd-bottom padd-right-off padd-left-off">

          <div className="sideinfo-left">
            <div className="rotate--90">
              <h6>Nasze Działania</h6>
            </div>
          </div>
          {/* <div className="sideinfo-right">
            <div className="rotate-90">
              <h6>Działania</h6>
            </div>
          </div> */}
          {/* <div className='heading padd padd-top-off text-center'>
            <h2>Pomoc</h2>
          </div> */}
         
          <div className="slider">
            {slides}
          </div>

        </section>

        <section ref={refAccounts} className="section-accounts c0">
          <div className='c0 shadow-card-container'>
          <div className='shadow-card padd bank-acc'>
            <h3>Wesprzyj Fundację</h3>
              <p>PLN 12 1020 4900 0000 8702 3433 5799</p>
              <p>EUR  28 1020 4900 0000 8902 3433 5801</p>
              <p>USD 95 1020 4900 0000 8002 3433 5812</p>
              <p>KRS 0000557191</p>
            </div>
          </div>
          
         
        </section>

        <Footer />
  
      </main>
      
    </>
  )
}

export default Page



export const query = graphql`
query {
  directus {
    articles(filter: {status: {_eq: "published"}, translations: {languages_id: {name: {_eq: "Polish"}}}}, limit: 4, sort: "-publish_date") {
      id
      slug
      status
      publish_date
      image {
        ...imgFull
      }
      translations(filter: {languages_id: {name: {_eq: "Polish"}}}) {
        id
        title
        summary
        body
      }
    }
  }
}

`
